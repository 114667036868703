import { Box, Typography, useTheme } from '@mui/material';

const Footer = () => {
  const theme = useTheme();

  return (
    <Box sx={{ paddingTop: `calc(${theme.custom.spacing} - 12px)`, paddingBottom: `calc(${theme.custom.spacing} - 4px)` }}>
      <Typography>Footer</Typography>
    </Box>
  );
}

export { Footer };
