import { Link } from '@mui/material';
import { Stack } from '@mui/system';
import { SiteLogo } from '../common';
import { SearchBox } from '../search/SearchBox';

const HomePage = () => {
  return (
    <Stack direction='column' alignItems='center'>
      <Link href='#' sx={{ marginY: '5%' }}>
        <SiteLogo sx={{ height: '100%', width: '100%' }} />
      </Link>
      <SearchBox autoFocus={true} useSuggestionHighlighting={true} />
    </Stack>
  );
}

export { HomePage };
