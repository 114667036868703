import { Typography } from '@mui/material';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';

type HighlightedTypographyProps = {
  text: string
  highlightTerm?: string;
}

const HighlightedTypography = (props: HighlightedTypographyProps) => {
  const { text, highlightTerm, ...passthroughProps } = props;

  if (!highlightTerm || !highlightTerm.length) {
    return <Typography {...passthroughProps}>{text}</Typography>;
  }

  const matches = AutosuggestHighlightMatch(text, highlightTerm, { insideWords: true, findAllOccurrences: true });
  const parts = AutosuggestHighlightParse(text, matches);

  return (
    <>
      {parts.map((part: any, index: number) => (
        <Typography
          key={`${text.toLowerCase()}-${index}`}
          component='span'
          fontWeight={part.highlight ? 'bold' : ''}
          {...passthroughProps}
        >
          {part.text}
        </Typography>
      ))}
    </>
  );
}

export { HighlightedTypography };
