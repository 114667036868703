import { Box, Grid, ListItem, Typography } from '@mui/material';
import { HighlightedTypography } from './HighlightedTypography';
import { SearchSuggestionRelatedItems } from './SearchSuggestionRelatedItems';
import { SearchItem } from '../../types/search';

type SearchSuggestionProps = {
  allSearchItems: SearchItem[];
  currentSearchTerm: string;
  searchItem: SearchItem;
  useSuggestionHighlighting?: boolean;
}

const SearchSuggestion = (props: SearchSuggestionProps) => {
  const {
    allSearchItems,
    currentSearchTerm,
    searchItem,
    useSuggestionHighlighting,
    ...passthroughProps
  } = props;

  const handleSearchItemClick = async (searchItem: SearchItem) => {
    window.location.href = searchItem.topicPageRoute;
  };

  const suggestionText = useSuggestionHighlighting
    ? <HighlightedTypography text={searchItem.label} highlightTerm={currentSearchTerm} />
    : <Typography>{searchItem.label}</Typography>;

  return (
    <ListItem
      {...passthroughProps}
      key={searchItem.key}
      sx={{
        width: '100%',
        px: 1,
        cursor: 'pointer',
      }}
      onClick={() => handleSearchItemClick(searchItem)}
    >
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Box sx={{ mr: 1}}>
          {suggestionText}
        </Box>
        <Box>
          <SearchSuggestionRelatedItems allSearchItems={allSearchItems} searchItem={searchItem} />
        </Box>
      </Grid>
    </ListItem>
  );
}

export { SearchSuggestion };
