import { useEffect, useState } from 'react';
import { getTopic } from '../clients/iabaContentClient';
import { Topic } from '../types/topic';

type useTopicParams = {
  topicKey: string,
}

const useTopic = (props: useTopicParams) => {
  const { topicKey } = props;

  const [data, setData] = useState<Topic | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [started, setStarted] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    setStarted(true);

    const fetchData = async () => {
      try {
        const topic: Topic = await getTopic(topicKey);

        setData(topic);
        setLoading(false);
      } catch (error: any) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [topicKey]);

  return { data, error, loading, started };
};

export { useTopic };
