import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { pageAppMetadata } from './config/pageAppMetadata';
import { PageAppMetadata } from './models/config';

const IabaApp = () => {
  /**
   * Why are routes defined elsewhere and built here in a loop?
   *
   * We have two types of pages:
   * - Well-known pages (e.g. home page, settings page) with routes specifically defined in this app's react code
   * - Dynamic pages (e.g. topic page) with routes that are defined by the topic content definition in iaba-content
   *
   * We want to generate a sitemap which exposes every well-known page and possible option for each dynamic page as a
   * well-known page, with the purpose of getting all of those pages indexed on Google. As a result, since we want any
   * publish from iaba-content to become available on the site immediately without making any change to this project, we
   * need to dynamically generate the sitemap from live data. In order to do that, we share page/route data between this
   * component and the sitemap generation script, so page definition data is defined in config.
   */
  return (
    <HelmetProvider>
      <Helmet>
        <title>I Am Bad At: What are you bad at?</title>
      </Helmet>
      <BrowserRouter>
        <Routes>
          {pageAppMetadata.map((page: PageAppMetadata) =>
            <Route key={page.route} path={page.route} element={page.component} />
          )}
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export { IabaApp };
