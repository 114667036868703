import { Chip, Stack, Typography } from '@mui/material';
import { SearchItem } from '../../types/search';

const FONT_SIZE: number = 10;
const RELATED_ITEM_TEXT: string = 'Related';

type SearchSuggestionRelatedItemsProps = {
  allSearchItems?: SearchItem[];
  searchItem?: SearchItem;
}

const SearchSuggestionRelatedItems = (props: SearchSuggestionRelatedItemsProps) => {
  const { allSearchItems, searchItem } = props;

  if (!allSearchItems || !searchItem) {
    return <></>;
  }

  let relatedSearchItems: SearchItem[] = [];
  searchItem?.relatedKeys?.forEach((relatedKey) => {
    relatedSearchItems.push(...allSearchItems.filter((si) => si.key === relatedKey));
  });

  if (!relatedSearchItems.length) {
    return <></>;
  }

  const handleChipClick = async (relatedSearchItem: SearchItem) => {
    window.location.href = relatedSearchItem.topicPageRoute;
  };

  return (
    <Stack direction='row' spacing={0.25}>
      <Typography
        sx={{
          justifyContent: 'center',
          fontSize: FONT_SIZE,
          fontStyle: 'italic',
          mr: 0.2,
        }}
      >
        {RELATED_ITEM_TEXT}:
      </Typography>
      {
        relatedSearchItems.map((rsi) => {
          return (
            <Chip
              key={`${searchItem.key}-related-${rsi.key}`}
              label={rsi.label}
              color='primary'
              sx={{
                fontSize: FONT_SIZE,
                height: '80%',
              }}
              onClick={(event) => {
                event.stopPropagation();
                handleChipClick(rsi);
              }}
            />
          );
        })
      }
    </Stack>
  );
}

export { SearchSuggestionRelatedItems };
