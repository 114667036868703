import { Box } from '@mui/material';
import { Footer, Header } from '../common';

const SiteProfilePage = () => {
  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Header />
      <Box sx={{ flexGrow: 1 }}>
        <h2>Profile page</h2>
      </Box>
      <Footer />
    </Box>
  );
}

export { SiteProfilePage };
