const FEATURE_QUERY_STRING_PARAM = 'feature';

export enum FeatureFlag {
  TopicAssess = 'assess',
  TopicLearn = 'learn',
  TopicDiscover = 'discover',
  TopicSocial = 'social',
  TopicShare = 'share',
  ProfilePage = 'profilePage',
  SettingsPage = 'settingsPage',
  ShopPage = 'shopPage',
}

export function getActiveFeatures(window: Window): FeatureFlag[] {
  const activeFeatures: FeatureFlag[] = [];

  const queryStringParams = new URLSearchParams(window.location.search);
  const featureParam: string | null = queryStringParams.get(FEATURE_QUERY_STRING_PARAM);
  if (!featureParam) {
    return activeFeatures;
  }

  const potentialFeatures: string[] | undefined = featureParam?.split(',');
  if (!potentialFeatures) {
    return activeFeatures;
  }

  Object.entries(FeatureFlag).forEach((key: [string, FeatureFlag]) => {
    const [, enumValue] = key;
    if (potentialFeatures.includes(enumValue)) {
      activeFeatures.push(enumValue);
    }
  });

  return activeFeatures;
}
