import { topicPageConfig } from '../config/topic';
import { TopicPageActivity, TopicPageActivityDefinition, TopicPagePlacement } from '../models/topic';
import { FeatureFlag, getActiveFeatures } from './featureService';

export const getActivityDefinition = (activity: TopicPageActivity): TopicPageActivityDefinition | undefined => {
  return topicPageConfig.activityDefinitions
    .filter((definition: TopicPageActivityDefinition) => definition.activity === activity)
    .at(0);
}

export const getRankedActivities = (placement: TopicPagePlacement, filterFeatureFlags?: boolean): TopicPageActivityDefinition[] => {
  let filtered: TopicPageActivityDefinition[] = topicPageConfig.activityDefinitions
    .filter((definition: TopicPageActivityDefinition) => definition.placements.includes(placement));

  if (filterFeatureFlags) {
    const activeFeatures: FeatureFlag[] = getActiveFeatures(window);
    filtered = filtered.filter((definition: TopicPageActivityDefinition) =>
      !definition.featureFlag || activeFeatures.includes(definition.featureFlag));
  }

  return filtered.sort((x: TopicPageActivityDefinition, y: TopicPageActivityDefinition) => x.rank - y.rank);
}
