import { Topic } from '../../types/topic';

type TopicViewSocialProps = {
  topic: Topic;
}

// Purpose: search social media to find others who are bad at the same thing
// Easy if we generate the post, harder if we need to search social media ourselves
const TopicViewSocial = (props: TopicViewSocialProps) => {
  const { topic } = props;

  return (
    <>
      {topic && (<p>{topic.key}</p>)}
    </>
  );
}

export { TopicViewSocial };
