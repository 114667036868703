import axios from 'axios';
import { appConfig } from '../config/app';
import { SearchItem } from '../types/search';
import { Topic } from '../types/topic';

export async function getSearchItems(): Promise<SearchItem[]> {
  const url: string = `${appConfig.contentUrl}/search.json`;
  const response = await axios.get<SearchItem[]>(url);
  return response?.data;
}

export async function getTopic(topicKey: string): Promise<Topic> {
  const url: string = `${appConfig.contentUrl}/topics/${topicKey}.json`;
  const response = await axios.get<Topic>(url);
  return response?.data;
}
