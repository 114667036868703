import { Topic } from '../../types/topic';

type TopicViewDiscoverProps = {
  topic: Topic;
}

// Purpose: Discover products/services which can help you improve
const TopicViewDiscover = (props: TopicViewDiscoverProps) => {
  const { topic } = props;

  return (
    <>
      {topic && (<p>{topic.key}</p>)}
    </>
  );
}

export { TopicViewDiscover };
