import { FeatureFlag } from '../services/featureService';

export enum VideoSource {
  YouTube = 'YouTube',
}

export type TopicPageConfig = {
  defaultActivity: TopicPageActivity;
  activityDefinitions: TopicPageActivityDefinition[];
}

export enum TopicPageActivity {
  Overview,
  Statistics,
  Assess,
  Learn,
  Discover,
  Social,
  Home,
  Shop,
  Settings,
  Profile,
}

export type TopicPageActivityDefinition = {
  activity: TopicPageActivity;
  displayName: string;
  buildIcon: (props?: any) => JSX.Element;
  placements: TopicPagePlacement[];
  rank: number;
  href?: string;
  featureFlag?: FeatureFlag;
}

export enum TopicPagePlacement {
  MenuTop,
  MenuBottom,
  Overview,
}
