import { ArrowForwardIosRounded, Close, Menu } from '@mui/icons-material';
import { Box, CircularProgress, Divider, Drawer, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { Footer } from '../common';
import {
  TopicNotFound,
  TopicPageMenu,
  TopicViewAssess,
  TopicViewDiscover,
  TopicViewLearn,
  TopicViewOverview,
  TopicViewSocial,
  TopicViewStatistics,
} from '../topic';
import { topicPageConfig } from '../../config/topic';
import { useTopic } from '../../hooks';
import { TopicPageActivity, TopicPageActivityDefinition } from '../../models/topic';
import { getActivityDefinition } from '../../services/topicService';
import { Topic } from '../../types/topic';

const HEADER_HEIGHT: string = '40px';

type TopicPageRouteParams = {
  topicKey: string;
}

const TopicPage = () => {
  const { topicKey } = useParams<keyof TopicPageRouteParams>() as TopicPageRouteParams;
  const { data, error, loading, started } = useTopic({ topicKey });
  const theme = useTheme();
  const displayStaticMenu: boolean = useMediaQuery(`(min-width: ${theme.custom.reactiveWidthTrigger}px)`);
  // TODO: another action for IABA store products customized to this topic
  // TODO: have users guess the statistic data before showing them: could be a quiz tab or an option on how to view the statistics tab
  const [currentActivity, setCurrentActivity] = useState<TopicPageActivity>(topicPageConfig.defaultActivity);
  const [menuDrawerOpen, setMenuDrawerOpen] = useState<boolean>(false);

  const buildDisplayView = (activity: TopicPageActivity, topic: Topic) => {
    switch (activity) {
      case TopicPageActivity.Overview:
        return <TopicViewOverview setActivityCallback={setCurrentActivity} topic={topic} />;
      case TopicPageActivity.Assess:
        return <TopicViewAssess topic={topic} />;
      case TopicPageActivity.Statistics:
          return <TopicViewStatistics topic={topic} />;
      case TopicPageActivity.Discover:
        return <TopicViewDiscover topic={topic} />;
      case TopicPageActivity.Learn:
        return <TopicViewLearn topic={topic} />;
      case TopicPageActivity.Social:
        return <TopicViewSocial topic={topic} />;
      default:
        return <CircularProgress />;
    }
  }

  let mainContentBody = undefined;
  if (!started || loading) {
    mainContentBody = <CircularProgress />;
  } else if (error) {
    mainContentBody = <TopicNotFound />;
  } else if (data) {
    let viewHeader = undefined;
    if (currentActivity !== TopicPageActivity.Overview) {
      const activityDefinition: TopicPageActivityDefinition | undefined = getActivityDefinition(currentActivity);
      if (activityDefinition) {
        viewHeader = (
          <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
            <Typography variant='h5'>{activityDefinition.displayName}</Typography>
            <IconButton onClick={() => setCurrentActivity(TopicPageActivity.Overview)}><Close /></IconButton>
          </Box>
        );
      }
    }

    mainContentBody = (
      <>
        {viewHeader}
        {buildDisplayView(currentActivity, data)}
      </>
    );
  }

  const pageHeader = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography
        variant='h4'
        sx={{
          fontWeight: 'bold',
          color: theme.custom.highlightColor,
          lineHeight: HEADER_HEIGHT,
        }}
      >
        {data?.displayTitle}
      </Typography>
      {!displayStaticMenu && (
        <>
          <IconButton
            onClick={() => setMenuDrawerOpen(true)}
            sx={{ height: HEADER_HEIGHT, width: HEADER_HEIGHT }}
          >
            <Menu fontSize='inherit' />
          </IconButton>
          <Drawer
            anchor='right'
            open={menuDrawerOpen}
            onClose={() => setMenuDrawerOpen(false)}
            PaperProps={{
              sx: {
                display: 'flex',
                flexDirection: 'column',
                padding: theme.custom.spacing,
              },
            }}
          >
            <IconButton
              onClick={() => setMenuDrawerOpen(false)}
              sx={{ height: HEADER_HEIGHT, width: HEADER_HEIGHT, marginBottom: theme.custom.spacing }}
            >
              <ArrowForwardIosRounded fontSize='inherit' />
            </IconButton>
            <Divider flexItem={true} sx={{ marginBottom: theme.custom.spacing }}/>
            <TopicPageMenu
              headerHeight={HEADER_HEIGHT}
              setActivityCallback={setCurrentActivity}
              showLogo={false}
            />
          </Drawer>
        </>
      )}
    </Box>
  );

  return (
    <>
      <Helmet>
        <title>{`I am bad at ${data?.displayTitle.toLowerCase()}`}</title>
      </Helmet>
      <Box sx={{ display: 'flex', height: '100vh', width: '100vw' }}>
        {displayStaticMenu && (
          <>
            <TopicPageMenu
              headerHeight={HEADER_HEIGHT}
              padding={theme.custom.spacing}
              setActivityCallback={setCurrentActivity}
            />
            <Divider flexItem={true} orientation='vertical' />
          </>
        )}
        <Box
          sx={{
            flex: 1,
            height: '100%',
            padding: theme.custom.spacing,
            display: 'flex',
            flexDirection: 'column',
            gap: theme.custom.spacing,
          }}
        >
          {pageHeader}
          <Divider flexItem={true} />
          <Box sx={{ flex: 1 }}>
            {mainContentBody}
          </Box>
          <Divider flexItem={true} />
          <Footer />
        </Box>
      </Box>
    </>
  );
}

export { TopicPage };
