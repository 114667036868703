import { SvgIcon, SxProps, Theme, useTheme } from '@mui/material';

type SiteLogoProps = {
  defaultColor?: string;
  highlightColor?: string;
  sx?: SxProps<Theme>; // TODO: Finding a way to use SvgIconProps might be preferable
}

const SiteLogo = (props: SiteLogoProps) => {
  const {
    defaultColor,
    highlightColor,
    ...passthroughProps
  } = props;

  const theme = useTheme();

  const finalDefaultColor = defaultColor || '#000000';
  const finalHighlightColor = highlightColor || theme.custom.highlightColor || '#ff3131';

  return (
    <SvgIcon viewBox="3 -2 370 87" {...passthroughProps}>
      <g fill={finalHighlightColor} fill-opacity="1">
        <g transform="translate(1.019022, 83.415279)">
          <path d="M 3.1875 -73.15625 L 3.1875 -84.578125 L 19.578125 -84.578125 L 19.578125 -73.15625 Z M 3.1875 0 L 3.1875 -68.5625 L 19.578125 -68.5625 L 19.578125 0 Z M 3.1875 0" />
        </g>
      </g>
      <g fill={finalDefaultColor} fill-opacity="1">
        <g transform="translate(23.729923, 83.415279)">
          <path d="M 15.734375 0.75 C 10.429688 0.75 6.796875 -1.007812 4.828125 -4.53125 C 2.859375 -8.0625 1.875 -13.109375 1.875 -19.671875 C 1.875 -25.046875 2.578125 -29.164062 3.984375 -32.03125 C 5.390625 -34.90625 7.335938 -37.015625 9.828125 -38.359375 C 12.328125 -39.703125 16.140625 -41.1875 21.265625 -42.8125 L 25.765625 -44.3125 L 25.765625 -51.328125 C 25.765625 -53.140625 25.40625 -54.53125 24.6875 -55.5 C 23.96875 -56.46875 23.109375 -56.953125 22.109375 -56.953125 C 21.234375 -56.953125 20.46875 -56.546875 19.8125 -55.734375 C 19.15625 -54.921875 18.828125 -53.828125 18.828125 -52.453125 L 18.828125 -48.328125 L 2.90625 -48.328125 L 2.90625 -50.578125 C 2.90625 -57.328125 4.476562 -62.132812 7.625 -65 C 10.78125 -67.875 15.921875 -69.3125 23.046875 -69.3125 C 28.722656 -69.3125 33.34375 -67.828125 36.90625 -64.859375 C 40.46875 -61.898438 42.25 -57.703125 42.25 -52.265625 L 42.25 0 L 25.953125 0 L 25.953125 -8.15625 C 25.140625 -5.34375 23.84375 -3.15625 22.0625 -1.59375 C 20.28125 -0.03125 18.171875 0.75 15.734375 0.75 Z M 22.203125 -11.984375 C 23.453125 -11.984375 24.34375 -12.5 24.875 -13.53125 C 25.40625 -14.5625 25.671875 -15.828125 25.671875 -17.328125 L 25.671875 -36.4375 C 23.234375 -35.5 21.4375 -34.3125 20.28125 -32.875 C 19.125 -31.4375 18.546875 -29.441406 18.546875 -26.890625 L 18.546875 -18.078125 C 18.546875 -14.015625 19.765625 -11.984375 22.203125 -11.984375 Z M 22.203125 -11.984375" />
        </g>
        <g transform="translate(68.966323, 83.415279)">
          <path d="M 3.28125 -68.5625 L 19.296875 -68.5625 L 19.296875 -60.609375 C 20.046875 -63.546875 21.328125 -65.726562 23.140625 -67.15625 C 24.953125 -68.59375 27.507812 -69.3125 30.8125 -69.3125 C 33.5 -69.3125 35.828125 -68.703125 37.796875 -67.484375 C 39.765625 -66.265625 41.15625 -64.597656 41.96875 -62.484375 C 43.34375 -64.847656 44.867188 -66.578125 46.546875 -67.671875 C 48.234375 -68.765625 50.578125 -69.3125 53.578125 -69.3125 C 58.953125 -69.3125 62.679688 -67.671875 64.765625 -64.390625 C 66.859375 -61.117188 67.90625 -56.359375 67.90625 -50.109375 L 67.71875 0 L 51.234375 0 L 51.234375 -50.390625 C 51.234375 -54.828125 50.109375 -57.046875 47.859375 -57.046875 C 46.304688 -57.046875 45.1875 -56.28125 44.5 -54.75 C 43.8125 -53.21875 43.46875 -51.390625 43.46875 -49.265625 L 43.46875 0 L 27.078125 0 L 27.078125 -50.390625 C 27.078125 -52.328125 26.875 -53.890625 26.46875 -55.078125 C 26.0625 -56.265625 25.203125 -56.859375 23.890625 -56.859375 C 22.203125 -56.859375 21.015625 -56.03125 20.328125 -54.375 C 19.640625 -52.71875 19.296875 -50.734375 19.296875 -48.421875 L 19.296875 0 L 3.28125 0 Z M 3.28125 -68.5625" />
        </g>
      </g>
      <g fill={finalHighlightColor} fill-opacity="1">
        <g transform="translate(139.891724, 83.415279)">
          <path d="M 29.78125 0.75 C 25.289062 0.75 22.078125 -1.9375 20.140625 -7.3125 L 20.140625 0 L 3.28125 0 L 3.28125 -80.9375 L 20.140625 -80.9375 L 20.140625 -65.296875 C 22.765625 -67.972656 25.820312 -69.3125 29.3125 -69.3125 C 34.625 -69.3125 38.4375 -67.734375 40.75 -64.578125 C 43.0625 -61.429688 44.21875 -56.890625 44.21875 -50.953125 L 44.21875 -17.890625 C 44.21875 -12.265625 43.03125 -7.75 40.65625 -4.34375 C 38.28125 -0.945312 34.65625 0.75 29.78125 0.75 Z M 24.265625 -11.984375 C 25.390625 -11.984375 26.1875 -12.34375 26.65625 -13.0625 C 27.125 -13.78125 27.40625 -14.6875 27.5 -15.78125 C 27.59375 -16.875 27.640625 -18.578125 27.640625 -20.890625 L 27.640625 -49.453125 C 27.640625 -51.890625 27.421875 -53.703125 26.984375 -54.890625 C 26.546875 -56.078125 25.609375 -56.671875 24.171875 -56.671875 C 22.609375 -56.671875 21.546875 -56.078125 20.984375 -54.890625 C 20.421875 -53.703125 20.140625 -51.953125 20.140625 -49.640625 L 20.140625 -20.890625 C 20.140625 -17.890625 20.390625 -15.65625 20.890625 -14.1875 C 21.390625 -12.71875 22.515625 -11.984375 24.265625 -11.984375 Z M 24.265625 -11.984375" />
        </g>
        <g transform="translate(186.813967, 83.415279)">
          <path d="M 15.734375 0.75 C 10.429688 0.75 6.796875 -1.007812 4.828125 -4.53125 C 2.859375 -8.0625 1.875 -13.109375 1.875 -19.671875 C 1.875 -25.046875 2.578125 -29.164062 3.984375 -32.03125 C 5.390625 -34.90625 7.335938 -37.015625 9.828125 -38.359375 C 12.328125 -39.703125 16.140625 -41.1875 21.265625 -42.8125 L 25.765625 -44.3125 L 25.765625 -51.328125 C 25.765625 -53.140625 25.40625 -54.53125 24.6875 -55.5 C 23.96875 -56.46875 23.109375 -56.953125 22.109375 -56.953125 C 21.234375 -56.953125 20.46875 -56.546875 19.8125 -55.734375 C 19.15625 -54.921875 18.828125 -53.828125 18.828125 -52.453125 L 18.828125 -48.328125 L 2.90625 -48.328125 L 2.90625 -50.578125 C 2.90625 -57.328125 4.476562 -62.132812 7.625 -65 C 10.78125 -67.875 15.921875 -69.3125 23.046875 -69.3125 C 28.722656 -69.3125 33.34375 -67.828125 36.90625 -64.859375 C 40.46875 -61.898438 42.25 -57.703125 42.25 -52.265625 L 42.25 0 L 25.953125 0 L 25.953125 -8.15625 C 25.140625 -5.34375 23.84375 -3.15625 22.0625 -1.59375 C 20.28125 -0.03125 18.171875 0.75 15.734375 0.75 Z M 22.203125 -11.984375 C 23.453125 -11.984375 24.34375 -12.5 24.875 -13.53125 C 25.40625 -14.5625 25.671875 -15.828125 25.671875 -17.328125 L 25.671875 -36.4375 C 23.234375 -35.5 21.4375 -34.3125 20.28125 -32.875 C 19.125 -31.4375 18.546875 -29.441406 18.546875 -26.890625 L 18.546875 -18.078125 C 18.546875 -14.015625 19.765625 -11.984375 22.203125 -11.984375 Z M 22.203125 -11.984375" />
        </g>
        <g transform="translate(232.050368, 83.415279)">
          <path d="M 16.765625 0.75 C 12.765625 0.75 9.75 -0.203125 7.71875 -2.109375 C 5.695312 -4.015625 4.359375 -6.539062 3.703125 -9.6875 C 3.046875 -12.84375 2.71875 -16.890625 2.71875 -21.828125 L 2.90625 -52.734375 C 2.90625 -57.609375 4.132812 -61.585938 6.59375 -64.671875 C 9.0625 -67.765625 12.546875 -69.3125 17.046875 -69.3125 C 21.484375 -69.3125 24.796875 -67.847656 26.984375 -64.921875 L 26.984375 -80.9375 L 43.375 -80.9375 L 43.375 0 L 26.984375 0 L 26.984375 -7.3125 C 26.046875 -4.625 24.78125 -2.609375 23.1875 -1.265625 C 21.59375 0.078125 19.453125 0.75 16.765625 0.75 Z M 23.046875 -12.078125 C 24.671875 -12.078125 25.734375 -12.875 26.234375 -14.46875 C 26.734375 -16.0625 26.984375 -18.203125 26.984375 -20.890625 L 26.984375 -49.546875 C 26.984375 -51.546875 26.703125 -53.234375 26.140625 -54.609375 C 25.578125 -55.984375 24.578125 -56.671875 23.140625 -56.671875 C 21.515625 -56.671875 20.4375 -56.03125 19.90625 -54.75 C 19.375 -53.46875 19.109375 -51.734375 19.109375 -49.546875 L 19.109375 -20.703125 C 19.109375 -14.953125 20.421875 -12.078125 23.046875 -12.078125 Z M 23.046875 -12.078125" />
        </g>
      </g>
      <g fill={finalDefaultColor} fill-opacity="1">
        <g transform="translate(278.6272, 83.415279)">
          <path d="M 2.53125 0 L 2.53125 -13.671875 L 18.921875 -13.671875 L 18.921875 0 Z M 2.53125 0" />
        </g>
        <g transform="translate(300.074658, 83.415279)">
          <path d="M 15.734375 0.75 C 10.429688 0.75 6.796875 -1.007812 4.828125 -4.53125 C 2.859375 -8.0625 1.875 -13.109375 1.875 -19.671875 C 1.875 -25.046875 2.578125 -29.164062 3.984375 -32.03125 C 5.390625 -34.90625 7.335938 -37.015625 9.828125 -38.359375 C 12.328125 -39.703125 16.140625 -41.1875 21.265625 -42.8125 L 25.765625 -44.3125 L 25.765625 -51.328125 C 25.765625 -53.140625 25.40625 -54.53125 24.6875 -55.5 C 23.96875 -56.46875 23.109375 -56.953125 22.109375 -56.953125 C 21.234375 -56.953125 20.46875 -56.546875 19.8125 -55.734375 C 19.15625 -54.921875 18.828125 -53.828125 18.828125 -52.453125 L 18.828125 -48.328125 L 2.90625 -48.328125 L 2.90625 -50.578125 C 2.90625 -57.328125 4.476562 -62.132812 7.625 -65 C 10.78125 -67.875 15.921875 -69.3125 23.046875 -69.3125 C 28.722656 -69.3125 33.34375 -67.828125 36.90625 -64.859375 C 40.46875 -61.898438 42.25 -57.703125 42.25 -52.265625 L 42.25 0 L 25.953125 0 L 25.953125 -8.15625 C 25.140625 -5.34375 23.84375 -3.15625 22.0625 -1.59375 C 20.28125 -0.03125 18.171875 0.75 15.734375 0.75 Z M 22.203125 -11.984375 C 23.453125 -11.984375 24.34375 -12.5 24.875 -13.53125 C 25.40625 -14.5625 25.671875 -15.828125 25.671875 -17.328125 L 25.671875 -36.4375 C 23.234375 -35.5 21.4375 -34.3125 20.28125 -32.875 C 19.125 -31.4375 18.546875 -29.441406 18.546875 -26.890625 L 18.546875 -18.078125 C 18.546875 -14.015625 19.765625 -11.984375 22.203125 -11.984375 Z M 22.203125 -11.984375" />
        </g>
        <g transform="translate(345.311058, 83.415279)">
          <path d="M 17.421875 0.75 C 13.109375 0.75 10.140625 -0.15625 8.515625 -1.96875 C 6.898438 -3.78125 6.09375 -6.679688 6.09375 -10.671875 L 6.09375 -53.484375 L 1.5 -53.484375 L 1.5 -65.9375 L 6.09375 -65.9375 L 6.09375 -80.46875 L 22.015625 -80.46875 L 22.015625 -65.9375 L 26.703125 -65.9375 L 26.703125 -53.484375 L 22.015625 -53.484375 L 22.015625 -14.984375 C 22.015625 -13.796875 22.109375 -12.921875 22.296875 -12.359375 C 22.484375 -11.796875 22.984375 -11.515625 23.796875 -11.515625 C 24.421875 -11.515625 25.015625 -11.546875 25.578125 -11.609375 C 26.140625 -11.671875 26.515625 -11.703125 26.703125 -11.703125 L 26.703125 -0.46875 C 25.765625 -0.21875 24.40625 0.046875 22.625 0.328125 C 20.84375 0.609375 19.109375 0.75 17.421875 0.75 Z M 17.421875 0.75 " />
        </g>
      </g>
    </SvgIcon>
  );
}

export { SiteLogo };
