import Carousel from 'react-material-ui-carousel';
import { StylizedTopicStatistic } from './StylizedTopicStatistic';
import { TopicStatistic } from '../../types/topic';
import { Box, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';

type TopicStatisticCarouselProps = {
  topicStatistics: TopicStatistic[];
}

const CAROUSEL_AUTO_ROTATE_SECONDS = 60;

const TopicStatisticCarousel = (props: TopicStatisticCarouselProps) => {
  const { topicStatistics } = props;

  const theme = useTheme();
  const horizontalDisplay: boolean = useMediaQuery(`(min-width: ${theme.custom.reactiveWidthTrigger}px)`);

  const statisticDisplayHeight: string = `${horizontalDisplay ? 100 : 50}%`;
  const statisticDisplayWidth: string = `${horizontalDisplay ? 50 : 100}%`;

  return (
    <Carousel
      navButtonsAlwaysVisible={true}
      swipe={true}
      cycleNavigation={false}
      navButtonsProps={{
        style: {
          margin: 0,
        },
      }}
      indicators={true}
      indicatorContainerProps={{
        style: {
          marginTop: `calc(${theme.custom.spacing} * -2)`,
        },
      }}
      interval={CAROUSEL_AUTO_ROTATE_SECONDS * 1000}
      animation='slide'
      duration={theme.custom.animationLengthInMilliseconds}
      height='100%'
      sx={{ height: '100%', width: '100%' }}
    >
      {topicStatistics.map((topicStatistic: TopicStatistic) =>
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: horizontalDisplay ? 'row' : 'column',
            borderRadius: theme.custom.spacing,
          }}
        >
          <Box
            sx={{
              height: statisticDisplayHeight,
              width: statisticDisplayWidth,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography>Statistic display here</Typography>
          </Box>
          <Divider flexItem={true} orientation={horizontalDisplay ? 'vertical' : 'horizontal'} />
          <Box sx={{ height: statisticDisplayHeight, width: statisticDisplayWidth }}>
            <StylizedTopicStatistic
              key={topicStatistic.key}
              topicStatistic={topicStatistic}
              sx={{ minHeight: '16rem', padding: theme.custom.spacing }}
            />
          </Box>
        </Box>
      )}
    </Carousel>
  );
}

export { TopicStatisticCarousel };
