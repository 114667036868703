import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import { ResponsiveYouTubeVideo } from '../common';
import { TopicPageActivity, TopicPageActivityDefinition, TopicPagePlacement, VideoSource } from '../../models/topic';
import { Topic } from '../../types/topic';
import { FeatureFlag, getActiveFeatures } from '../../services/featureService';
import { getRankedActivities } from '../../services/topicService';

type TopicViewOverviewProps = {
  setActivityCallback: (activity: TopicPageActivity) => void;
  topic: Topic;
}

const TopicViewOverview = (props: TopicViewOverviewProps) => {
  const { setActivityCallback, topic } = props;

  const theme = useTheme();

  const buildOverviewVideo = (topic: Topic) => {
    const overviewVideoConfig = topic.overviewVideo;
    if (!overviewVideoConfig) {
      return undefined;
    }

    switch (overviewVideoConfig.source) {
      case VideoSource.YouTube:
        return (
          <ResponsiveYouTubeVideo
            aspectRatio={overviewVideoConfig.width / overviewVideoConfig.height}
            title={overviewVideoConfig.title}
            videoId={overviewVideoConfig.id}
          />
        );
      default:
        return undefined;
    }
  }

  const buildActivity = (definition: TopicPageActivityDefinition, activeFeatures: FeatureFlag[]) => {
    return (
      <Button
        variant='outlined'
        disabled={definition.featureFlag && !activeFeatures.includes(definition.featureFlag)}
        sx={{
          minHeight: '140px',
          minWidth: '140px',
          maxWidth: '140px',
          flexGrow: 1,
          padding: theme.custom.spacing,
          borderRadius: theme.custom.spacing,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          textTransform: 'none',
        }}
        onClick={() => setActivityCallback(definition.activity)}
      >
        {definition.buildIcon({ sx: { fontSize: '48px' } })}
        <Typography>{definition.displayName}</Typography>
      </Button>
    );
  }

  const activeFeatures: FeatureFlag[] = getActiveFeatures(window);
  const overviewActivities: TopicPageActivityDefinition[] = getRankedActivities(TopicPagePlacement.MenuTop);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme.custom.spacing, alignItems: 'center' }}>
      {topic.overviewVideo && (
        <>
          <Box sx={{ height: '100%', width: '100%', maxWidth: '768px' }}>
            {buildOverviewVideo(topic)}
          </Box>
          <Divider flexItem={true} />
        </>
      )}
      <Box sx={{ }}>
        <Typography sx={{ marginBottom: theme.custom.spacing, marginX: 'auto' }}>Explore</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: theme.custom.spacing }}>
          {overviewActivities.map((definition: TopicPageActivityDefinition) => buildActivity(definition, activeFeatures))}
        </Box>
      </Box>
    </Box>
  );
}

// TODO: Add custom message element

export { TopicViewOverview };
