import { HTMLAttributes, ReactNode, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  InputAdornment,
  Paper,
  TextField,
} from '@mui/material';
import { SearchSuggestion } from './SearchSuggestion';
import { getSearchItems } from '../../clients/iabaContentClient';
import { SearchItem } from '../../types/search';

const DEFAULT_UNFOCUSED_ELEVATION: number = 4;
const DEFAULT_SIZE = 'medium';
const DEFAULT_WIDTH = '50%';
const FOCUSED_ELEVATION: number = 4;
const MIN_SEARCH_TEXT_LENGTH_TO_DISPLAY_DROPDOWN: number = 1;

type SearchBoxSize = 'medium' | 'small';

type SearchBoxProps = {
  autoFocus?: boolean;
  size?: SearchBoxSize;
  unfocusedElevation?: number;
  useSuggestionHighlighting?: boolean;
  width?: string;
}

const SearchBox = (props: SearchBoxProps) => {
  const { autoFocus, size, unfocusedElevation, useSuggestionHighlighting, width } = props;
  const finalUnfocusedElevation: number = unfocusedElevation ?? DEFAULT_UNFOCUSED_ELEVATION;
  const finalSize: SearchBoxSize = size ?? DEFAULT_SIZE;
  const finalWidth: string = width ?? DEFAULT_WIDTH;

  const [elevation, setElevation] = useState<number>(finalUnfocusedElevation);
  const [inputValue, setInputValue] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [allSearchItems, setAllSearchItems] = useState<SearchItem[]>([]);

  const customPaperComponent = (customPaperComponentPassthroughProps: any) => {
    // Custom paper exists purely to control elevation display of the dropdown
    return <Paper elevation={FOCUSED_ELEVATION} {...customPaperComponentPassthroughProps} />;
  }

  const handleFocus = async () => {
    setOpen(inputValue.length >= MIN_SEARCH_TEXT_LENGTH_TO_DISPLAY_DROPDOWN);
    setElevation(FOCUSED_ELEVATION);
  };
  const handleInputChange = async (event: any, newInputValue: any) => {
    setInputValue(newInputValue);

    const shouldOpen: boolean = newInputValue.length >= MIN_SEARCH_TEXT_LENGTH_TO_DISPLAY_DROPDOWN;
    if (shouldOpen && !allSearchItems?.length) {
      // Since this uses await, consider whether to do before or after setOpen()
      //  1. Maybe we can display a spinner if there's a difference in load time
      //  2. Another option: load search data on focus rather than waiting for inputChange()
      const allSearchItems: SearchItem[] = await getSearchItems();
      setAllSearchItems(allSearchItems);
    }
    setOpen(shouldOpen);
  };
  const handleChange = async (event: any, value: string | SearchItem | null) => {
    if (!value) return;
    const searchItem = value as SearchItem;
    window.location.href = searchItem.topicPageRoute; // Navigates when dropdown value is selected via enter key
  };
  const handleClose = async () => setOpen(false); // Closes autocomplete dropdown on loss of focus
  const handleInputBlur = async () => setElevation(finalUnfocusedElevation); // Sets elevation on input loss of focus

  return (
    // TODO: For unsupported search terms, use onChange prop to open dialog or automatically submit change?
    //  See https://mui.com/material-ui/react-autocomplete/#creatable
    // TODO: Might want to change display of the search box to match Google styling
    //  See https://stackoverflow.com/questions/73092296/material-ui-with-react-how-to-style-autocomplete-with-rounded-corners
    <Autocomplete
      freeSolo
      autoHighlight={true}
      options={allSearchItems}
      size={finalSize}
      sx={{
        width: finalWidth,
        boxShadow: elevation,
      }}
      renderInput={(props: AutocompleteRenderInputParams) => {
        return (
          <TextField
            {...props}
            autoFocus={autoFocus}
            placeholder='What are you bad at?'
            InputProps={{
              ...props.InputProps, // InputProps is an override, also include this to prevent breaking autocomplete
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onBlur={handleInputBlur}
          />
        );
      }}
      renderOption={(
        props: HTMLAttributes<HTMLLIElement>,
        option: SearchItem,
        state: AutocompleteRenderOptionState): ReactNode => {
        const { inputValue } = state;

        return (
          <SearchSuggestion
            allSearchItems={allSearchItems}
            currentSearchTerm={inputValue}
            searchItem={option}
            useSuggestionHighlighting={useSuggestionHighlighting}
            {...props}
          />
        );
      }}
      PaperComponent={customPaperComponent}
      // Custom state management to prevent dropdown from opening until some input from user
      inputValue={inputValue}
      open={open}
      onFocus={handleFocus}
      onInputChange={handleInputChange}
      onChange={handleChange}
      onClose={handleClose}
    />
  );
}

export { SearchBox };
