import { Topic } from '../../types/topic';

type TopicViewAssessProps = {
  topic: Topic;
}

// Purpose: Assess how you compare with thers
// This helps us build out own data supply rather than seeking externally, plus drives Learn and/or Discover
const TopicViewAssess = (props: TopicViewAssessProps) => {
  const { topic } = props;

  return (
    <>
      {topic && (<p>{topic.key}</p>)}
    </>
  );
}

export { TopicViewAssess };
