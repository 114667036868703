import { pageMetadata } from './pageMetadata';
import {
  ROOT_ROUTE,
  SITE_ALL_TOPICS_ROUTE,
  SITE_PROFILE_ROUTE,
  SITE_SETTINGS_ROUTE,
  SITE_STORE_ROUTE,
  TOPIC_ROUTE
} from './routes';
import {
  HomePage,
  SiteAllTopicsPage,
  SiteProfilePage,
  SiteSettingsPage,
  SiteStorePage,
  TopicPage
} from '../components/pages';
import { PageAdditionalAppMetadata, PageAppMetadata } from '../models/config';

// This file is a .tsx file because we're referencing React components here
const pageAppMetadataMap: Map<string, PageAdditionalAppMetadata> = new Map<string, PageAdditionalAppMetadata>([
  [ROOT_ROUTE, { component: <HomePage /> }],
  [SITE_ALL_TOPICS_ROUTE, { component: <SiteAllTopicsPage /> }],
  [SITE_PROFILE_ROUTE, { component: <SiteProfilePage /> }],
  [SITE_SETTINGS_ROUTE, { component: <SiteSettingsPage /> }],
  [SITE_STORE_ROUTE, { component: <SiteStorePage /> }],
  [TOPIC_ROUTE, { component: <TopicPage /> }],
]);

export const pageAppMetadata: PageAppMetadata[] = pageMetadata.map((page) => {
  const additionalMetadata: PageAdditionalAppMetadata | undefined = pageAppMetadataMap.get(page.route);
  if (!additionalMetadata) {
    throw Error('Missing additional app metadata');
  }

  return { ...page, ...additionalMetadata };
});
