import { TopicStatisticCarousel } from './TopicStatisticCarousel';
import { Topic } from '../../types/topic';

type TopicViewStatisticsProps = {
  topic: Topic;
}

// Purpose: navigate through statistics that explain how bad people are at something

// Ideas:
// * Slider on divider line between data visualization and insight statistic
// * Data visualizations:
// ** Percentage breakdown: pie chart
// ** Change over time: bar chart or line chart
// ** Proximity to 0: concentric circles, bar chart, summed bar chart
// * Multiple visualization styles on same data set
// * Rebrand to "Data Insights" w/ Visualize + Key Insight
// * Button to expand data visualization to full screen
// * Button to submit new key insight
// * Button to see additional info on source
// ** _SOMETHING_ icon to describe and link to source (possible embed?)
// ** Person icon to describe makeup of data population
// ** Person icon to describe size of data population
// ** Clock icon to describe time of data collection
// * Button to share data visualization / key insight
const TopicViewStatistics = (props: TopicViewStatisticsProps) => {
  const { topic } = props;

  return (topic.statistics && topic.statistics.length > 0)
    ? <TopicStatisticCarousel topicStatistics={topic.statistics} />
    : <></>;
}

export { TopicViewStatistics };
