import { Box, Divider, Link, List, ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import { SiteLogo } from '../common';
import { SearchBox } from '../search';
import { ROOT_ROUTE } from '../../config/routes';
import { TopicPageActivity, TopicPageActivityDefinition, TopicPagePlacement } from '../../models/topic';
import { FeatureFlag, getActiveFeatures } from '../../services/featureService';
import { getRankedActivities } from '../../services/topicService';

const DEFAULT_HEADER_HEIGHT: string = '40px';
const DEFAULT_PADDING: string = '0px';
const DEFAULT_SHOW_LOGO: boolean = true;

type TopicPageMenuParams = {
  headerHeight?: string;
  padding?: string;
  setActivityCallback: (activity: TopicPageActivity) => void;
  showLogo?: boolean;
}

const TopicPageMenu = (props: TopicPageMenuParams) => {
  const { headerHeight, padding, setActivityCallback, showLogo } = props;

  const theme = useTheme();

  const buildMenuItem = (definition: TopicPageActivityDefinition, activeFeatures: FeatureFlag[]) => {
    if (definition.href) {
      return (
        <ListItemButton
          key={definition.displayName}
          href={definition.href}
          disabled={definition.featureFlag && !activeFeatures.includes(definition.featureFlag)}
        >
          <ListItemIcon>{definition.buildIcon()}</ListItemIcon>
          <ListItemText primary={definition.displayName} />
        </ListItemButton>
      );
    }

    return (
      <ListItemButton
        key={definition.displayName}
        onClick={() => setActivityCallback(definition.activity)}
        disabled={definition.featureFlag && !activeFeatures.includes(definition.featureFlag)}
      >
        <ListItemIcon>{definition.buildIcon()}</ListItemIcon>
        <ListItemText primary={definition.displayName} />
      </ListItemButton>
    );
  }

  const finalHeaderHeight: string = headerHeight ?? DEFAULT_HEADER_HEIGHT;
  const finalPadding: string = padding ?? DEFAULT_PADDING;
  const finalShowLogo: boolean = showLogo ?? DEFAULT_SHOW_LOGO;

  const activeFeatures: FeatureFlag[] = getActiveFeatures(window);
  const menuTopActivities: TopicPageActivityDefinition[] = getRankedActivities(TopicPagePlacement.MenuTop);
  const menuBottomActivities: TopicPageActivityDefinition[] = getRankedActivities(TopicPagePlacement.MenuBottom, true);

  return (
    <Box sx={{ minWidth: '300px', height: '100%', padding: finalPadding, display: 'flex', flexDirection: 'column' }}>
      {finalShowLogo && (
        <>
          <Box
            sx={{
              height: finalHeaderHeight,
              marginBottom: theme.custom.spacing,
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Link href={ROOT_ROUTE} sx={{ height: 'inherit', width: 'inherit', marginX: 'auto' }}>
              <SiteLogo sx={{ height: 'inherit', width: 'inherit', marginX: 'auto' }} />
            </Link>
          </Box>
          <Divider flexItem={true} sx={{marginBottom: theme.custom.spacing }} />
        </>
      )}
      <Box sx={{ marginBottom: theme.custom.spacing }}>
        <SearchBox size='small' unfocusedElevation={0} useSuggestionHighlighting={true} width='100%' />
      </Box>
      <Divider flexItem={true} />
      <List sx={{ flexGrow: 1, paddingY: `calc(${theme.custom.spacing} - 8px)` }}>
        {menuTopActivities.map((definition: TopicPageActivityDefinition) => buildMenuItem(definition, activeFeatures))}
      </List>
      <Divider flexItem={true} />
      <List sx={{ paddingTop: `calc(${theme.custom.spacing} - 8px)`, paddingBottom: 0 }}>
        {menuBottomActivities.map((definition: TopicPageActivityDefinition) => buildMenuItem(definition, activeFeatures))}
      </List>
    </Box>
  )
}

export { TopicPageMenu }
