const toFullDateString = (date: Date): string => {
  return date.toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' });
}

// TODO: Minimize output by removing unnecessary months and years in string
export const formatDateInterval = (startDateString: string, endDateString: string): string => {
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);

  return `${toFullDateString(startDate)} - ${toFullDateString(endDate)}`;
}
