import { useEffect, useState } from 'react';
import { getSearchItems } from '../clients/iabaContentClient';
import { SearchItem } from '../types/search';

const useSearchItems = () => {
  const [data, setData] = useState<SearchItem[] | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [started, setStarted] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    setStarted(true);

    const fetchData = async () => {
      try {
        const searchItems: SearchItem[] = await getSearchItems();

        setData(searchItems);
        setLoading(false);
      } catch (error: any) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, error, loading, started };
};

export { useSearchItems };
