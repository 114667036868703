import {
  ROOT_ROUTE,
  SITE_ALL_TOPICS_ROUTE,
  SITE_PROFILE_ROUTE,
  SITE_SETTINGS_ROUTE,
  SITE_STORE_ROUTE,
  TOPIC_ROUTE,
} from './routes';
import { PageMetadata } from '../models/config';

export const pageMetadata: PageMetadata[] = [
  { route: ROOT_ROUTE },
  { route: SITE_ALL_TOPICS_ROUTE },
  { route: SITE_PROFILE_ROUTE },
  { route: SITE_SETTINGS_ROUTE },
  { route: SITE_STORE_ROUTE },
  { route: TOPIC_ROUTE },
];
