import { Box, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Footer, Header } from '../common';
import { useSearchItems } from '../../hooks';

const SiteAllTopicsPage = () => {
  const { data, error, loading, started } = useSearchItems();

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Header />
      <Box sx={{ flexGrow: 1 }}>
        <h2>All topics</h2>
        {started && !loading && !error && data && (
          <List>
            {data.map((searchItem) =>
              <ListItem dense={true}>
                <ListItemButton component='a' href={searchItem.topicPageRoute} >
                  <ListItemText primary={searchItem.label} />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        )}
      </Box>
      <Footer />
    </Box>
  );
}

export { SiteAllTopicsPage };
