import { Box } from '@mui/material';

type ResponsiveYouTubeVideoProps = {
  aspectRatio: number;
  title: string;
  videoId: string;
}

const ResponsiveYouTubeVideo = (props: ResponsiveYouTubeVideoProps) => {
  const { aspectRatio, videoId, title } = props;

  return (
    <Box sx={{ position: 'relative', paddingBottom: `${100 / aspectRatio}%` }}>
      <iframe
        style={{ border: 0, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        src={`https://www.youtube.com/embed/${videoId}`}
        title={title}
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        allowFullScreen
      />
    </Box>
  );
}

export { ResponsiveYouTubeVideo };
