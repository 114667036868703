import {
  AccountCircle,
  BarChart,
  Groups,
  Home,
  Quiz,
  School,
  Settings,
  ShoppingBag,
  ShoppingCart
} from '@mui/icons-material';
import { TopicPagePlacement, TopicPageConfig, TopicPageActivity } from '../models/topic';
import { FeatureFlag } from '../services/featureService';
import { ROOT_ROUTE, SITE_STORE_ROUTE, SITE_SETTINGS_ROUTE, SITE_PROFILE_ROUTE } from './routes';

// This file is a .tsx file because we're referencing React components here
export const topicPageConfig: TopicPageConfig = {
  defaultActivity: TopicPageActivity.Overview,
  activityDefinitions: [
    {
      activity: TopicPageActivity.Statistics,
      displayName: 'Statistics',
      buildIcon: (props?: any) => (<BarChart {...props} />),
      placements: [TopicPagePlacement.MenuTop, TopicPagePlacement.Overview],
      rank: 10,
    },
    {
      activity: TopicPageActivity.Assess,
      displayName: 'Assess',
      buildIcon: (props?: any) => (<Quiz {...props} />),
      placements: [TopicPagePlacement.MenuTop, TopicPagePlacement.Overview],
      rank: 20,
      featureFlag: FeatureFlag.TopicAssess,
    },
    {
      activity: TopicPageActivity.Learn,
      displayName: 'Learn',
      buildIcon: (props?: any) => (<School {...props} />),
      placements: [TopicPagePlacement.MenuTop, TopicPagePlacement.Overview],
      rank: 30,
      featureFlag: FeatureFlag.TopicLearn,
    },
    {
      activity: TopicPageActivity.Discover,
      displayName: 'Discover',
      buildIcon: (props?: any) => (<ShoppingCart {...props} />),
      placements: [TopicPagePlacement.MenuTop, TopicPagePlacement.Overview],
      rank: 40,
      featureFlag: FeatureFlag.TopicDiscover,
    },
    {
      activity: TopicPageActivity.Social,
      displayName: 'Social',
      buildIcon: (props?: any) => (<Groups {...props} />),
      placements: [TopicPagePlacement.MenuTop, TopicPagePlacement.Overview],
      rank: 50,
      featureFlag: FeatureFlag.TopicSocial,
    },
    {
      activity: TopicPageActivity.Home,
      displayName: 'Home',
      buildIcon: (props?: any) => (<Home {...props} />),
      placements: [TopicPagePlacement.MenuBottom],
      rank: 70,
      href: ROOT_ROUTE,
    },
    {
      activity: TopicPageActivity.Shop,
      displayName: 'Shop',
      buildIcon: (props?: any) => (<ShoppingBag {...props} />),
      placements: [TopicPagePlacement.MenuBottom],
      rank: 80,
      href: SITE_STORE_ROUTE,
      featureFlag: FeatureFlag.ShopPage,
    },
    {
      activity: TopicPageActivity.Settings,
      displayName: 'Settings',
      buildIcon: (props?: any) => (<Settings {...props} />),
      placements: [TopicPagePlacement.MenuBottom],
      rank: 90,
      href: SITE_SETTINGS_ROUTE,
      featureFlag: FeatureFlag.SettingsPage,
    },
    {
      activity: TopicPageActivity.Profile,
      displayName: 'Profile',
      buildIcon: (props?: any) => (<AccountCircle {...props} />),
      placements: [TopicPagePlacement.MenuBottom],
      rank: 100,
      href: SITE_PROFILE_ROUTE,
      featureFlag: FeatureFlag.ProfilePage,
    },
  ],
};
