import { QuestionMark } from '@mui/icons-material';
import { AppBar, Divider, IconButton, Link, Toolbar } from '@mui/material';
import { SiteLogo } from './SiteLogo';
import { SearchBox } from '../search/SearchBox';
import { ROOT_ROUTE } from '../../config/routes';

const Header = () => {
  return (
    <AppBar color='transparent' elevation={0} position='static'>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {/* Font size avoids 6 pixels of extra height, but no issue as this only contains the site logo*/}
        <Link href={ROOT_ROUTE} fontSize={0}>
          <SiteLogo sx={{ height: '40px', width: '100%' }}/>
        </Link>
        <SearchBox size='small' unfocusedElevation={0} useSuggestionHighlighting={true} width='30%' />
        <IconButton>
          <QuestionMark />
        </IconButton>
      </Toolbar>
      <Divider />
    </AppBar>
  );
}

export { Header };
