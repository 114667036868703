import { Topic } from '../../types/topic';

type TopicViewLearnProps = {
  topic: Topic;
}

// Purpose: learn the best ways to improve
// Ideally with suggestioms backed by data, this can drive Discover
const TopicViewLearn = (props: TopicViewLearnProps) => {
  const { topic } = props;

  return (
    <>
      {topic && (<p>{topic.key}</p>)}
    </>
  );
}

export { TopicViewLearn };
