import { Theme, createTheme } from '@mui/material/styles';
import {
  manropeBold,
  manropeLight,
  manropeMedium,
  manropeRegular,
  manropeSemibold,
  manropeThin,
} from './static/fonts/manrope';

// Required to allow injection of custom data into MUI theme
declare module '@mui/material/styles' {
  // Extension to general theme interface
  interface Theme {
    custom: {
      highlightColor: string;
      spacing: string;
      reactiveWidthTrigger: number;
      animationLengthInMilliseconds: number;
    };
  }
  // Extension to createTheme(...) parameter interface
  interface ThemeOptions {
    custom: {
      highlightColor: string;
      spacing: string;
      reactiveWidthTrigger: number;
      animationLengthInMilliseconds: number;
    };
  }
}

const MANROPE_FONT_NAME = 'Manrope';
const TYPOGRAPHY_FONT = `'${MANROPE_FONT_NAME}', sans-serif`;
const FILE_FORMAT = 'woff2';

const iabaAppStyleOverrides = `
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: '${MANROPE_FONT_NAME}';
  font-weight: 100;
  src: local('${MANROPE_FONT_NAME}'), url('${manropeThin}') format('${FILE_FORMAT}');
}

@font-face {
  font-family: '${MANROPE_FONT_NAME}';
  font-weight: 200;
  src: local('${MANROPE_FONT_NAME}'), url('${manropeLight}') format('${FILE_FORMAT}');
}

@font-face {
  font-family: '${MANROPE_FONT_NAME}';
  font-weight: 300 normal;
  src: local('${MANROPE_FONT_NAME}'), url('${manropeRegular}') format('${FILE_FORMAT}');
}

@font-face {
  font-family: '${MANROPE_FONT_NAME}';
  font-weight: 400;
  src: local('${MANROPE_FONT_NAME}'), url('${manropeMedium}') format('${FILE_FORMAT}');
}

@font-face {
  font-family: '${MANROPE_FONT_NAME}';
  font-weight: 500;
  src: local('${MANROPE_FONT_NAME}'), url('${manropeSemibold}') format('${FILE_FORMAT}');
}

@font-face {
  font-family: '${MANROPE_FONT_NAME}';
  font-weight: 600 700 800 900 bold;
  src: local('${MANROPE_FONT_NAME}'), url('${manropeBold}') format('${FILE_FORMAT}');
}
`;

const iabaAppTheme: Theme = createTheme({
  typography: {
    fontFamily: TYPOGRAPHY_FONT,
    fontWeightLight: 200,
    fontWeightRegular: 300,
    fontWeightBold: 400,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: iabaAppStyleOverrides,
    },
  },
  custom: {
    // TODO: It would be really cool to let users configure highlightColor themselves
    highlightColor: '#ff3131', // dodgerBlue, #ff3131
    spacing: '16px',
    reactiveWidthTrigger: 768, // 1080
    animationLengthInMilliseconds: 500,
  },
});

export { iabaAppTheme };
