import { Box, BoxProps, Link, Typography, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import reactStringReplace from 'react-string-replace';
import { TopicStatistic, TopicStatisticSourcePopulation } from '../../types/topic';
import { formatDateInterval } from '../../utils/dateUtils';

type StylizedTopicStatisticProps = BoxProps & {
  topicStatistic: TopicStatistic;
}

const TOKEN_THAT_WILL_NEVER_MATCH = 'TOKEN_THAT_WILL_NEVER_EVER_MATCH';
const FONT_SIZE = '2rem';

const formatPopulation = (population: TopicStatisticSourcePopulation): string => {
  return `${population.size} ${population.description}`;
}

const StylizedTopicStatistic = (props: StylizedTopicStatisticProps) => {
  const { topicStatistic, sx } = props;

  const theme = useTheme();

  // TODO: Shouldn't need to do this initial replace to get the right data type
  let finalContent = reactStringReplace(topicStatistic.tokenizedContent, TOKEN_THAT_WILL_NEVER_MATCH, () => (<></>));
  topicStatistic.replacements.forEach((replacement) => {
    finalContent = reactStringReplace(finalContent, `$TOKEN:${replacement.key}`, (match: string, i: number) => (
      <Typography
        component='span'
        key={`${topicStatistic.key}.${match}.${i}`}
        color={theme.custom.highlightColor}
        fontSize={FONT_SIZE}
        fontWeight='bold'
      >
        {replacement.value}
      </Typography>
    ));
  });

  const source = topicStatistic.source;

  return (
    <Box sx={sx} display='flex' flexDirection='column' justifyContent='space-between'>
      <Typography component='div' fontSize={FONT_SIZE}>
        {finalContent}
      </Typography>
      <Stack direction='column' alignItems='flex-end'>
        <Typography component='div' sx={{ textAlign: 'right' }}>
          Source: <Link href={source.href}>{source.description}</Link>
        </Typography>
        {source.population && (
          <Typography component='div' sx={{ fontSize: '.8rem', color: 'grey', textAlign: 'right' }}>
            {formatPopulation(source.population)}
          </Typography>
        )}
        {source.timeframe && (
          <Typography component='div' sx={{ fontSize: '.8rem', color: 'grey', textAlign: 'right' }}>
            {formatDateInterval(source.timeframe.startDate, source.timeframe.endDate)}
          </Typography>
        )}
      </Stack>
    </Box>
  );
}

export { StylizedTopicStatistic };
